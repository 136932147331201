import React, { useEffect, useState } from "react";
import "./styles.css";
import { BrowserRouter as Router, Switch, Redirect } from "react-router-dom";
import theme from "./components/Theme";
import { makeStyles, ThemeProvider } from "@material-ui/core/styles";
import Header from "./components/Header";
import Footer from "./components/Footer";
import RouteAuth from "./components/RouteAuth";
import { useSelector } from "react-redux";
import { isLoaded, isEmpty } from "react-redux-firebase";
import { NoticePrivacy, Promotions, Requests, Companies, Categories, TagCategories, Logo } from "./views";
import PublicRouter from "./PublicRouter";
import { CssBaseline } from "@material-ui/core";

const containerStyle = {
  backgroundColor: "#fdfdfd",
  backgroundRepeat: "no-repeat",
  backgroundAttachment: "fixed",
  backgroundPosition: "center",
  minHeight: "100vh",
  fontFamily: "roboto",
};

const bodyStyles = {
  margin: 0,
  padding: 0,
};

export default () => {
  const auth = useSelector((state) => state.firebase.auth);
  const [_, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    try {
      if (isLoaded(auth) && !isEmpty(auth)) {
        setUser(auth);
      }
    } catch (error) {
      setUser(null);
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [auth]);

  if (!isLoaded(auth)) return null;

  const AuthRouter = () => (
    <Router>
      {isEmpty(auth) && !loading && <Redirect to="/login" />}
      {!isEmpty(auth) && !loading && <Header hideHeaderLinks />}
      <div style={!isEmpty(auth) && !loading ? bodyStyles : null}>
        <Switch>
          <RouteAuth exact path="/promotions" component={Promotions} />
          <RouteAuth exact path="/companies" component={Companies} />
          <RouteAuth exact path="/requests" component={Requests} />
          <RouteAuth exact path="/noticePrivacy" component={NoticePrivacy} />
          <RouteAuth exact path="/categories" component={Categories} />
          <RouteAuth exact path="/tag-categories" component={TagCategories} />
          <RouteAuth exact path="/logo" component={Logo} />
        </Switch>
      </div>
      {!isEmpty(auth) && !loading && <Footer />}
    </Router>
  );

  return (
    <div style={containerStyle}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {isEmpty(auth) && !loading ? <PublicRouter /> : <AuthRouter />}
      </ThemeProvider>
    </div>
  );
};
