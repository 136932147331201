import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { HomeView, LogInView } from "./views";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import HomeAppbar from "./components/Header/HomeAppbar";

const useStyles = makeStyles((theme) => ({
  body: {
    margin: 0,
    padding: 0,
  },
  root: {
    backgroundColor: "#fafafa",
    flexGrow: 1,
  },
  tooolbar: {
    padding: "0px 120px",
  },
  toolbarIcon: {
    marginRight: theme.spacing(10),
    height: 50,
  },
  title: {
    flexGrow: 1,
    fontSize: 16,
  },
  logo: {
    width: 340,
    marginTop: -70,
    [theme.breakpoints.down("sm")]: {
      width: 100,
      marginTop: 0,
    },
  },
}));

const PublicRouter = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.body}>
      <Router>
        <HomeAppbar />
        <Switch>
          <Route exact path="/" component={HomeView} />
          <Route exact path="/login" component={LogInView} />
        </Switch>
      </Router>
    </div>
  );
};

export default PublicRouter;
