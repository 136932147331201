import { createMuiTheme } from "@material-ui/core/styles";
export default createMuiTheme({
  palette: {
    primary: {
      main: "#eaeaea",
    },
    secondary: {
      main: "#395200",
    },
  },
  overrides: {
    MuiButton: {
      containedSecondary: {
        color: "#ffffff",
      },
    },
  },
});
