import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, CircularProgress } from '@material-ui/core';

const DeleteDialog = ({ open, onCancel, onAccept, loading }) => {
  return (
    <Dialog open={open}>
      <DialogTitle>¡Advertencia!</DialogTitle>
      <DialogContent>
        <DialogContentText>
        ¿Seguro que desea eliminar este registro?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} style={{color: '#232e5c'}}>
          Cancelar
        </Button>
        {
          loading 
          ? 
            <CircularProgress style={{color: '#232e5c'}} />
          :  
            <Button onClick={onAccept}  style={{color: '#232e5c'}}  autoFocus>
              Aceptar
            </Button>
        }
      </DialogActions>
  </Dialog>
  );
};

export default DeleteDialog;