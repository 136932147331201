import React from 'react';
import 'typeface-roboto';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { MeetingRoom } from '@material-ui/icons';
import { Grid, Box } from '@material-ui/core';
import { Link, NavLink } from 'react-router-dom';
import logo from './../../white-logo.png';
import { withRouter } from 'react-router';
import MobileHeader from './MobileDrawer';
import firebase from 'firebase/app';
import DateRangeIcon from '@material-ui/icons/DateRange';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import HomeIcon from '@material-ui/icons/Home';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';

const links = [
  { to: '/', text: 'Inicio', icon: HomeIcon },
  { to: '/logo', text: 'Logo App', icon: InsertDriveFileIcon },
  { to: '/promotions', text: 'Promociones', icon: DateRangeIcon }, 
  { to: '/companies', text: 'Tienda Virtual', icon: BusinessCenterIcon },
    { to: '/tag-categories', text: 'Tipo Tienda', icon: BusinessCenterIcon },
  { to: '/categories', text: 'Categorias', icon: BusinessCenterIcon },
  { to: '/requests', text: 'Solicitudes', icon: InsertDriveFileIcon },
];

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    textTransform: 'uppercase',
    backgroundColor: '#fafafa',
    position: 'relative',
    zIndex: '999',
    boxShadow: '-1px -3px 18px 6px rgba(0,0,0,0.12)',
    height: '80px',
    '& > *': {
      height: '100%'
    },
    '& img': {
      width: '150px'
    }
  },
  mobileButton: {
    display: 'flex'
  },
  link: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    },
    textDecoration: 'none',
    fontFamily: 'roboto',
    color: '#575555',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: '0 12px',
    transition: 'all 0.2s ease',
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,0.07)'
    }
  },
  active: {
    backgroundColor: 'rgba(0,0,0,0.07)'
  },
  icon: {
    color: '#575555'
  }
}));

const getNavLinks = (links, classes) => {
  return links.map(link => {
    return (
      <NavLink
        exact={Boolean(link.to === '/')}
        key={link.to}
        to={link.to}
        activeClassName={classes.active}
        className={classes.link}>
        { link.text }
      </NavLink>
    )}
  )
}

const Header = ({ hideHeaderLinks }) => {
  const classes = useStyles();
  return (
    <Box className={classes.root} /* px={4}  */> 
      <Grid container >
        <Grid xs={1}
          className={classes.mobileButton}
          item
          container
          justify="center">
            <MobileHeader links={links}/>
        </Grid>
        <Grid item xs={2}>
          <Link to="/">
            <img src={logo} style={{marginTop: 25}}/>
          </Link>
        </Grid>
        <Grid
          xs={7}
          item 
          container 
          direction="row" 
          justify="flex-start" 
          alignItems="center">
          { 
            hideHeaderLinks?
              null 
              : getNavLinks(links, classes) 
          }
        </Grid>
        <Grid xs={2}
          className={classes.userOptions}
          item >
          <Grid 
            container 
            style={{ height: '100%' }} 
            direction="row" 
            justify="flex-end" 
            alignItems="center">
            <Button>
              <Link onClick={async () => await firebase.logout()} to="/login">
                <MeetingRoom className={classes.icon}/>
              </Link>
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

export default withRouter(Header);