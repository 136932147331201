import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/storage';
import 'firebase/firestore'

const firebaseConfig = {
  apiKey: "AIzaSyAt8IvasZ1KfM1--LAPLSbwS12I-ap0NmU",
  authDomain: "proyectopuente-app.firebaseapp.com",
  databaseURL: "https://proyectopuente-app.firebaseio.com",
  projectId: "proyectopuente-app",
  storageBucket: "proyectopuente-app.appspot.com",
  messagingSenderId: "275631652711",
  appId: "1:275631652711:web:01e03be92d2b892c8939c2"
};

firebase.initializeApp(firebaseConfig);
firebase.firestore();

export default firebase;