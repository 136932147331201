import asyncComponent from "../components/AsyncComponent";
const NoticePrivacy = asyncComponent(() =>
  import("./noticePrivacy/NoticePrivacyView")
);
const HomeView = asyncComponent(() => import("./Home/HomeView"));
const LogInView = asyncComponent(() => import("./LogInView"));
const Promotions = asyncComponent(() => import("./Promotions/PromotionsView"));
const Companies = asyncComponent(() => import("./Companies/CompaniesView"));
const Requests = asyncComponent(() => import("./Requests/RequestsView"));
const TagCategories = asyncComponent(() => import("./TagCategories/TagCategoriesView"));
const Categories = asyncComponent(() => import("./Categories/CategoriesView"));
const Logo = asyncComponent(() => import("./Logo/LogoView"));

export {
  HomeView,
  LogInView,
  NoticePrivacy,
  Promotions,
  Requests,
  Companies,
  TagCategories,
  Logo,
  Categories
};
