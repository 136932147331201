import React from "react";
import { NavLink, useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import AccountCircle from "@material-ui/icons/AccountCircle";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles((theme) => ({
  body: {
    margin: 0,
    padding: 0,
  },
  root: {
    backgroundColor: "#fafafa",
    flexGrow: 1,
  },
  toolbar: {},
  toolbarIcon: {
    marginRight: theme.spacing(10),
    height: 50,
  },
  title: {
    flexGrow: 1,
    fontSize: 16,
  },
  logo: {
    width: 340,
    marginTop: -70,
    [theme.breakpoints.down("sm")]: {
      width: 100,
      marginTop: 0,
    },
  },
}));

const HomeAppbar = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const matches = useMediaQuery((theme) => theme.breakpoints.down("xs"));

  return (
    <AppBar className={classes.toolbar} position="static">
      <Toolbar>
        <img
          className={classes.toolbarIcon}
          src={require("../../white-logo.png")}
        />

        {!matches && (
          <>
            {[
              { link: "https://proyectopuente.com.mx", label: "Información" },
              // { link: "/entrevistas", label: "Entrevistas" },
              // { link: "/opinion", label: "Opinión" },
              // { link: "/especiales", label: "Especiales" },
              // { link: "/punteros", label: "Punteros" },
            ].map((e, i) => (
              <Typography key={i} className={classes.title}>
                <NavLink to={e.link}>{e.label}</NavLink>
              </Typography>
            ))}

            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={() => history.push("/login")}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default HomeAppbar;
