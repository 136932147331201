import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    height:'200px',
    alignItems:'center',
    display: 'flex',
    justifyContent: 'center',
  }
}));

const LoadingIndicator = ({ className }) => {
  const classes = useStyles();
  return (
    <div
      className={`${ classes.root } ${className? className : ''}`}>
      <CircularProgress style={{color: '#232e5c'}} />
    </div>
  )
};

export default LoadingIndicator;