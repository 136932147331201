import firebase from './../firebase';

class Services {
  async getCollection(collection) {
    return await firebase.firestore().collection(collection).get();
  }

  async getDocumnetById(collection, id) {
    return await firebase.firestore().collection(collection).doc(id).get();
  }


  async getCollectionWhere(collection, w1, w2) {
    return await firebase.firestore().collection(collection).where(w1, "==", w2).get();
  }

  async addDocument(collection, data) {
    return await firebase.firestore().collection(collection).add(data);
  }

  async updateDocument(collection, id, data) {
    return await firebase.firestore().collection(collection).doc(id).update(data);
  }

  async uploadFile(path, _file) {
    try {
      let newMetadata = {
        cacheControl: 'public,max-age=300',
        contentType: 'image/jpeg'
      }


      const file = await firebase.storage().ref().child(path).put(_file);
      const fileUrl = await file.ref.getDownloadURL();

      return fileUrl.toString();
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  async deleteFile(path) {
    return await firebase.storage().ref().child(path).delete();
  }

  async delete(collection, id) {
    return await firebase.firestore().collection(collection).doc(id).delete();
  }
}

export default new Services;